<template>
    <div>

        <div v-if="loading" class="text-center">
            <b-spinner
                class="mr-1"
                variant="primary"
            />
        </div>

        <div v-if="!loading">
            <!-- success -->
            <b-alert
                :variant="getStatusVariant()"
                show
            >
                <div class="alert-body">
                    <span>{{ getStatusLabel() }}</span>
                </div>
            </b-alert>
            <b-card
            >
                <div class="row">
                    <div class="col">
                        <b-button-group>
                            <b-button variant="danger" v-if="orden.status.toString()!=='0'" @click="changeStatus(0)">
                                Cancelar orden
                            </b-button>
                            <b-button variant="success" v-if="orden.status.toString()!=='2'" @click="changeStatus(2)">
                                Confirmar orden
                            </b-button>
                        </b-button-group>
                    </div>
                </div>
            </b-card>

            <b-card
                title="Información del cliente"
            >
                <div class="row">
                    <div class="col">
                        <div class="form-group">
                            <label>Nombre</label>
                            <input type="text" class="form-control" disabled :value="orden.profile.first_name">
                        </div>
                    </div>
                    <div class="col">
                        <div class="form-group">
                            <label>Apellido</label>
                            <input type="text" class="form-control" disabled :value="orden.profile.last_name">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col">
                        <div class="form-group">
                            <label>Correo electrónico</label>
                            <input type="text" class="form-control" disabled :value="orden.profile.email">
                        </div>
                    </div>
                    <div class="col">
                        <div class="form-group">
                            <label>Teléfono</label>
                            <input type="text" class="form-control" disabled :value="orden.profile.phone">
                        </div>
                    </div>
                </div>
            </b-card>

            <b-card
                title="Dirección del cliente"
            >
                <div class="row">
                    <div class="col">
                        <div class="form-group">
                            <label>Calle</label>
                            <input type="text" class="form-control" disabled :value="orden.delivery_address.street">
                        </div>
                    </div>
                    <div class="col">
                        <div class="form-group">
                            <label>Número exterior</label>
                            <input type="text" class="form-control" disabled
                                   :value="orden.delivery_address.outdoor_number">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col">
                        <div class="form-group">
                            <label>Colonia</label>
                            <input type="text" class="form-control" disabled :value="orden.delivery_address.colony">
                        </div>
                    </div>
                    <div class="col">
                        <div class="form-group">
                            <label>Código postal</label>
                            <input type="text" class="form-control" disabled
                                   :value="orden.delivery_address.postal_code">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="form-group">
                            <label>Estado</label>
                            <input type="text" class="form-control" disabled :value="orden.delivery_address.state">
                        </div>
                    </div>
                    <div class="col">
                        <div class="form-group">
                            <label>Referencias</label>
                            <input type="text" class="form-control" disabled :value="orden.delivery_address.references">
                        </div>
                    </div>
                </div>
            </b-card>


            <b-card
                title="Detalle de la orden"
            >
                <div class="row">
                    <div class="col">

                    </div>
                    <div class="col">
                        <div class="form-group">
                            <label>Total</label>
                            <input type="text" class="form-control" disabled :value="'$ ' + numberFormat(orden.total)">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <b-table
                            ref="refDetailItemsListTable"
                            class="position-relative"
                            :items="productDetails"
                            responsive
                            :fields="tableColumns"
                            primary-key="id"
                            show-empty
                            empty-text="No matching records found"
                        >

                            <template #cell(product)="data">
                                <b-avatar :src="data.item.product.images[0]" size="4rem" class="mr-1"></b-avatar>
                                {{ data.item.product.name }}
                            </template>

                            <template #cell(price)="data">
                                $ {{ numberFormat(data.item.price) }}
                            </template>

                        </b-table>
                    </div>
                </div>

            </b-card>
        </div>
    </div>
</template>

<script>
import store from "@/store"
import orderStoreModule from "@/views/orders/orderStoreModule"
import {onUnmounted, ref} from "@vue/composition-api"
import {BCard, BAlert, BTable, BButton, BButtonGroup, BSpinner, BAvatar} from "bootstrap-vue"
import ToastificationContent from "@core/components/toastification/ToastificationContent"
import {useToast} from "vue-toastification/composition"

export default {
    name: "OrderDetailsView",
    components: {
        BCard,
        BAlert,
        BTable,
        BButton,
        BButtonGroup,
        BSpinner,
        BAvatar
    },
    methods: {
        numberFormat(number) {
            number = parseFloat(number)
            let val = (number / 1).toFixed(2)//.replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },
        getStatusLabel() {
            switch (this.orden.status) {
                case 0:
                    return 'Cancelado'
                case 1:
                    return 'Pendiente de pago'
                case 2:
                    return 'Confirmado'
            }
        },
        getStatusVariant() {
            switch (this.orden.status) {
                case 0:
                    return 'danger'
                case 1:
                    return 'warning'
                case 2:
                    return 'success'
            }
        },
        changeStatus(status) {
            this.loading = true
            store
                .dispatch('app-orders/changeOrderStatus', {id: this.orderId, status}).then(() => {
                this.getOrder()
            })
        }
    },
    setup(props, {root}) {
        const USER_APP_STORE_MODULE_NAME = 'app-orders'

        // Register module
        if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, orderStoreModule)

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
        })
        const toast = useToast()
        const route = root.$route
        const refDetailItemsListTable = ref(null)
        const loading = ref(true)
        const orden = ref(null)

        const tableColumns = [
            //{key: 'id'},
            //{key: 'first_name', label: 'Nombre'},
            {key: 'quantity', label: 'Cantidad'},
            {key: 'product', label: 'Producto'},
            {key: 'price', label: 'Precio unitario'},
        ]

        const productDetails = ref([])

        const orderId = route.params.id

        const getOrder = () => {
            store
                .dispatch('app-orders/fetchOrder', orderId)
                .then(response => {
                    // console.log(response)
                    orden.value = response
                    productDetails.value = orden.value.order_details
                    loading.value = false
                })
                .catch(() => {
                    // console.log(err)
                    toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Error al obtener lista de ordenes',
                            icon: 'AlertTriangleIcon',
                            variant: 'danger',
                        },
                    })
                })
        }

        getOrder()

        return {
            tableColumns,
            productDetails,
            refDetailItemsListTable,
            loading,
            orden,
            getOrder,
            orderId,
        }
    }
}
</script>

<style scoped>

</style>
